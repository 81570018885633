import React from 'react';
import Layout, { Content } from '../components/Layout';
import CallBackRequest from '../components/forms/CallBackRequest';

const Callback = () => {
  return (
    <Layout title={'Request a Callback'}>
      <Content style={{ marginLeft: '0', margin: '0 auto' }}>
        <CallBackRequest />
      </Content>
    </Layout>
  );
};

export default Callback;