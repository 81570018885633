import React, { useState } from 'react';
import Form from './Form';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '@bunac/lib/themes';
import { FIELD_TYPES, DATA_MODELS } from './Form/Field';
import ThankYou from './ThankYou';

const FORM_ID = 'call_back_request';
const PIPELINE_ID = 3;

const formConfig = [
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Last Name',
    name: 'Last Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone',
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Country',
    name: 'Country of Residence',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Call Time',
    name: 'Daytime Preferences',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'How would you like to hear from us?',
    type: FIELD_TYPES.lineOfText,
    style: {
      marginTop: '20px',
      marginBottom: '10px',
    },
  },
  {
    label: 'Email',
    name: 'Communication Consent Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'SMS',
    name: 'Communication Consent SMS',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone',
    name: 'Communication Consent Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label:
      'To give you the best online customer experience, we would like to pass your data onto third parties, such as Facebook and Instagram. Are you ok for us to pass your data onto 3rd parties?',
    name: '3rd Party Data Consent',
    isRequired: false,
    model: DATA_MODELS.person,
  },
];

const CallbackRequest = () => {
  const [submitted, setSubmited] = useState(false);
  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return <ThankYou />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Form
        headline="Request a Callback"
        formId={FORM_ID}
        pipelineId={PIPELINE_ID}
        config={formConfig}
        initValues={null}
        onSubmit={submit}
        submitButton={true}
        buttonAlignment="left"
        buttonText={'Submit'}
      />
    </ThemeProvider>

  );
};

export default CallbackRequest;