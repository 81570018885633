import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0094A5',
    },
    secondary: {
      main: '#0094A5',
    },
  },
  overrides: {
    MuiInputBase: {
      root: {
        color: '#0094A5',

        '&:hover': {
          borderColor: '#0094A5',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        borderColor: '#0094A5',
      },
      notchedOutline: {
        borderColor: '#0094A5',
      },
    },
    MuiSelect: {
      root: {
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: 14,
        color: '#0094A5',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'proxima-nova, sans-serif',
        fontSize: 14,
        color: '#0094A5',
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: 'proxima-nova, sans-serif',
        fontWeight: 300,
        color: '#212121',
      },
    },
    MuiTextField: {
      root: {
        marginBottom: '12px',
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: '0',
      },
    },
  },
});

export { theme };
